import { create } from "zustand";
import { getConfig } from "../api";

const useConfigStore = create((set) => ({
  SCORE_INCREASE_PER_DIFFICULTY: null, // Points increase per difficulty level
  INITIAL_TIMER_DURATION: null, // Initial time per match, in seconds
  TIMES_PER_DAY: null,
  INITIAL_SCORE_PER_MATCH: null, // Starting points per correct match
  loadConfig: async () => {
    const res = await getConfig();
    const {
      initialScorePerMatch,
      scoreIncreasePerDifficulty,
      timeToMatch,
      timesPerDay,
    } = res.data?.attributes || {};
    set({
      INITIAL_SCORE_PER_MATCH: initialScorePerMatch,
      SCORE_INCREASE_PER_DIFFICULTY: scoreIncreasePerDifficulty,
      INITIAL_TIMER_DURATION: timeToMatch,
      // INITIAL_TIMER_DURATION: 666,
      TIMES_PER_DAY: timesPerDay,
    });
  },
}));

export { useConfigStore };
