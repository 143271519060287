import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import Circle from "./Circle";

const GameBoard = ({
  circles, // Array of circles to display
  clickable,
  onCircleClick, // Function to call when a circle is clicked
  flashingCircleId, // Id of the circle that is flashing
  clickedCircles, // Array of circle ids that have been clicked
  level, // User level
  isMatch, // Is match succeed
  lastClicked,
  currentClicked,
  pointsPerMatch,
}) => {
  const gameBoardRef = useRef(null);
  const circleRefs = useRef([]);
  const isCurrentChunk = circles.some((c) => c.id === currentClicked?.id);

  useEffect(() => {
    const gameBoard = gameBoardRef.current;
    const boardWidth = gameBoard.clientWidth;
    const boardHeight = gameBoard.clientHeight;
    // Set initial positions
    circleRefs.current.forEach((circle, index) => {
      const circleSize = circles[index].scale * circle.offsetWidth
      const initialX = Math.random() * (boardWidth - circleSize);
      const initialY = Math.random() * (boardHeight - circleSize);

      gsap.set(circle, {
        x: initialX,
        y: initialY,
      });

      let endX = Math.random() * (boardWidth - circleSize);
      let endY = Math.random() * (boardHeight - circleSize);

      const MIN_DURATION = 0.4; // Minimum gsap anim duration
      const DURATION_FACTOR = 6; // A factor to control the rate of decrease

      let newDuration = Math.max(
        MIN_DURATION,
        DURATION_FACTOR - Math.log2(1 + level)
      );

      // console.log('animation speed newDuration', newDuration);

      // Start animation
      gsap.to(circle, {
        x: endX,
        y: endY,
        duration: newDuration,
        ease: "power1.inOut",
        repeat: -1, // Repeat the animation indefinitely
        yoyo: true, // Move back and forth
        delay: Math.random() * 2, // Random delay for each circle
      });
    });
  }, [circles, level]);

  return (
    <div
      ref={gameBoardRef}
      className={`box ${
        typeof isMatch === "boolean" && isCurrentChunk
          ? isMatch
            ? "correct"
            : "wrong"
          : ""
      }`}
    >
      {circles.map((circle, idx) => (
        <Circle
          key={circle.id}
          id={circle.id}
          color={circle.color}
          label={circle.label}
          rotation={circle.rotation}
          scale={circle.scale}
          imgSrc={circle.imgSrc}
          wrongSrc={circle.wrongSrc}
          clickable={clickable}
          isAilment={circle.isAilment}
          isClicked={clickedCircles.includes(circle.id)}
          isFlashing={circle.id === flashingCircleId}
          isMatch={isMatch}
          lastClicked={lastClicked}
          currentClicked={currentClicked}
          pointsPerMatch={pointsPerMatch}
          onClick={() => onCircleClick(circle)}
          wrapperRef={circleRefs.current[idx]}
          ref={(el) => (circleRefs.current[idx] = el)} // Set ref here
        />
      ))}
    </div>
  );
};

export default GameBoard;
