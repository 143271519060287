import service from "./service";

export async function createPlayer(data) {
  const res = await service.post("/players", {
    data: data,
  });
  return res.data;
}

export async function updatePlayer(playerId, data) {
  const res = await service.put(`/players/${playerId}`, {
    data: data,
  });
  return res.data;
}

export async function getPlayers(params) {
  const res = await service.get(`/players`, {
    params,
  });
  return res.data;
}

export async function getPlayer(id) {
  const res = await service.get(`/players/${id}`);
  return res.data;
}

export async function createSession(data) {
  const res = await service.post("/game-sessions", {
    data: data,
  });
  return res.data;
}

export async function updateSession(sessionId, data) {
  const res = await service.put(`/game-sessions/${sessionId}`, {
    data: data,
  });
  return res.data;
}

export async function getSessions(params) {
  const res = await service.get(`/game-sessions`, {
    params,
  });
  return res.data;
}

export async function getSession(id) {
  const res = await service.get(`/game-sessions/${id}`);
  return res.data;
}

export async function getLeaderboardData(params) {
  const res = await service.get(`/leaderboard-rank`, {
    params,
  });
  return res.data;
}

export async function getConfig() {
  const res = await service.get("/game-config");
  return res.data;
}
