import { useEffect, useState, useCallback } from "react";
import Button from "../components/Button";
import { createPlayer, updatePlayer, getPlayers, getSessions } from "../api";
import { validateEmail } from "../utils";
import { useConfigStore } from "../store";
import TermsModal from "../components/Terms";
import useAudio from "../hooks/useAudio";
import Header from '../components/Header';

function Signup({ onSignup, onBack }) {
  const { playButton } = useAudio();
  const { TIMES_PER_DAY } = useConfigStore((state) => ({
    TIMES_PER_DAY: state.TIMES_PER_DAY,
  }));
  const [playerId, setPlayerId] = useState();
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [recommendReason, setRecommendReason] = useState();
  const [noRecommendReason, setNoRecommendReason] = useState();
  const [otherRecommendReason, setOtherRecommendReason] = useState();
  const [disabled, setDisabled] = useState(true);
  const [nameDisabled, setNameDisabled] = useState(false);
  const [mobileDisabled, setMobileDisabled] = useState(false);
  const [nameMsg, setNameMsg] = useState();
  const [emailMsg, setEmailMsg] = useState();
  const [mobileMsg, setMobileMsg] = useState();
  const [recommendReasonMsg, setRecommendReasonMsg] = useState();
  const [noRecommendReasonMsg, setNoRecommendReasonMsg] = useState();
  const [otherRecommendReasonMsg, setOtherRecommendReasonMsg] = useState();
  const [isReachedLimit, setIsReachedLimit] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const validateMobile = useCallback((value, isSilent) => { 
    const isValid = /^\+\d{1,3}\d{7,11}$|^\d{10}$/.test(value)

    if (!value) {
      !isSilent && setMobileMsg('Phone number is required')
    } else if (!isValid) {
      !isSilent && setMobileMsg('Please input a valid phone number')
    } else { 
      !isSilent && setMobileMsg()
    }

    return isValid
  }, [])

  const validateName = useCallback((value, isSilent) => {
    let isValid = false
    if (value?.trim()) {
      !isSilent && setNameMsg('')
      isValid = true
    } else { 
      !isSilent && setNameMsg('Please input a name')
    }
    return isValid
  }, [])

  const validateUserEmail = useCallback((value, isSilent) => { 
    let isValid = false
    if (!value?.trim()) {
      !isSilent && setEmailMsg('Please input an email')
    } else if (!validateEmail(value?.trim())) {
      !isSilent && setEmailMsg('Please input a valid email')
    } else {
      !isSilent && setEmailMsg('')
      isValid = true
    }
    return isValid
  }, [])

  const validate = useCallback((isSilent) => {
    const isNameDisabled = !validateName(name, isSilent)
    const isEmailDisabled = !validateUserEmail(email, isSilent)
    const isMobileDisabled = !validateMobile(mobile, isSilent)
    const isDisabled = isNameDisabled || isEmailDisabled || isMobileDisabled || isReachedLimit;
    setDisabled(isDisabled);

    if (isDisabled) { 
      return false
    }

    return true;
  }, [name, email, mobile, isReachedLimit, validateMobile, validateUserEmail, validateName]);

  const handleSignup = useCallback(async () => {
    playButton();
    if (!validate() || disabled || isReachedLimit) return;

    const playerRes = await getPlayers({ "filters[email][$eq]": email });

    if (playerRes.data.length > 0) {
      onSignup(playerRes.data[0].id, true)
    } else {
      const signup = document.querySelector(".signup");
      const recommend = document.querySelector(".recommend");
      const res = await createPlayer({
        name,
        email,
        mobile,
      });
      setPlayerId(res.data.id);
      onSignup(res.data.id)
      signup.classList.add("left");
      recommend.classList.remove("right");
    }
  }, [email, name, mobile, validate, disabled, isReachedLimit, onSignup, playButton]);

  const handleRecommend = useCallback(
    async (res) => {
      playButton();
      const recommend = document.querySelector(".recommend");
      const why = document.querySelector(".why");
      const why_not = document.querySelector(".why-not");

      await updatePlayer(playerId, {
        isRecommend: res === "yes",
      });
      recommend.classList.add("left");

      if (res === "yes") {
        why.classList.remove("right");
      } else {
        why_not.classList.remove("right");
      }
    },
    [playerId, playButton]
  );

  const handleReason = useCallback(
    async (isRecommend) => {
      playButton();
      const why_not = document.querySelector(".why-not");
      const competitor = document.querySelector(".competitor");
      const formData = {};
      if (isRecommend) {
        formData.recommendReason = recommendReason;
      } else {
        formData.notRecommendReason = noRecommendReason;
      }
      await updatePlayer(playerId, formData);
      if (isRecommend) {
        onSignup(playerId, true)
      } else {
        why_not.classList.add("left");
        competitor.classList.remove("right");
      }
    },
    [playerId, recommendReason, noRecommendReason, playButton, onSignup]
  );

  const handleOtherReason = useCallback(async () => {
    playButton();

    if (!otherRecommendReason) { 
      setOtherRecommendReasonMsg('Reason is required')
      return
    }

    await updatePlayer(playerId, {
      otherRecommend: otherRecommendReason,
    });
    onSignup(playerId, true)
  }, [playerId, otherRecommendReason, onSignup, playButton]);

  const handleShowTerms = () => {
    playButton();
    setShowTermsModal(true);
  };

  useEffect(() => {
    const checkEmail = async () => {
      const sessionRes = await getSessions({
        "filters[player][email][$eq]": email,
        "filters[createdAt][$gte]": new Date(
          new Date().setHours(0, 0, 0, 0)
        ).toISOString(),
        "filters[createdAt][$lte]": new Date(
          new Date().setHours(23, 59, 59, 0)
        ).toISOString(),
      });
      const playerRes = await getPlayers({ "filters[email][$eq]": email });

      const todayPlayCount = sessionRes.data.length;
      const player = playerRes.data[0];

      if (player) {
        setName(player.attributes.name);
        setMobile(player.attributes.mobile)
        setNameDisabled(true);
        setMobileDisabled(true)
      } else {
        setNameDisabled(false);
        setMobileDisabled(false);
      }

      if (todayPlayCount >= TIMES_PER_DAY) {
        setIsReachedLimit(true);
        setEmailMsg(
          "The maximum number of plays for this email has been reached for today."
        );
      } else {
        setIsReachedLimit(false);
      }
    };

    if (validateEmail(email)) {
      checkEmail();
    } else {
      setIsReachedLimit(false);
      setNameDisabled(false);
      setMobileDisabled(false);
    }
  }, [email, TIMES_PER_DAY]);

  useEffect(() => { 
    if (typeof recommendReason === 'string') {
      if (recommendReason) {
        setRecommendReasonMsg()
      } else {
        setRecommendReasonMsg('Reason is required')
      }
    } else { 
      setRecommendReasonMsg()
    }
  }, [recommendReason])

  useEffect(() => { 
    if (typeof noRecommendReason === 'string') {
      if (noRecommendReason) {
        setNoRecommendReasonMsg()
      } else {
        setNoRecommendReasonMsg('Reason is required')
      }
    } else { 
      setNoRecommendReasonMsg()
    }
  }, [noRecommendReason])

  useEffect(() => { 
    if (typeof otherRecommendReason === 'string') {
      if (otherRecommendReason) {
        setOtherRecommendReasonMsg()
      } else {
        setOtherRecommendReasonMsg('Reason is required')
      }
    } else { 
      setOtherRecommendReasonMsg()
    }
  }, [otherRecommendReason])

  useEffect(() => { 
    validate(true)
  }, [name, email, mobile, validate])

  return (
    <>
      <img
        src="/assets/image/background.png"
        className="background-image"
        alt="background"
      />
      <div className="page-container page-signup h-screen">
        <Header />
        {/* <!-- sign up form --> */}
        <div className="inner signup">
          <h2 className="bold text-center text-[2.08vw] mobile:text-[6.4vw]">
            Enter your details below to WIN an iPad.
          </h2>
          <form className="signup-form space-y-[1vw] mobile:space-y-[6vw]" autoComplete="off">
            <div className="mx-auto w-full px-[1vw] space-y-2">
              <input
                className={`input_name w-full rounded-[20px] h-[5.156vw] text-[1.5625vw] mobile:text-[3.84vw] overflow-hidden ${
                  nameDisabled
                    ? "!bg-gray-400 cursor-not-allowed !border-gray-200"
                    : ""
                }`}
                defaultValue={undefined}
                type="text"
                value={name}
                aria-label="name"
                name="full_name"
                placeholder="Full Name"
                autoComplete="off"
                disabled={nameDisabled}
                onChange={(e) => setName(e.target.value)}
                onInput={(e) => validateName(e.target.value)}
                onBlur={() => validate()}
                required
              />
              {typeof nameMsg === "string" && (
                <p className="text-red-500 !mb-0 text-[1.2vw] mobile:text-[2.4vw] text-center">{nameMsg}</p>
              )}
            </div>
            <div className="mx-auto w-full px-[1vw] space-y-2">
              <input
                className="input_email w-full h-[5.156vw] text-[1.5625vw] mobile:text-[3.84vw]"
                defaultValue={undefined}
                type="email"
                aria-label="email"
                name="user_email"
                placeholder="Email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                onInput={(e) => validateUserEmail(e.target.value)}
                onBlur={() => validate()}
                required
              />
              {(typeof emailMsg === "string" || isReachedLimit) && (
                <p className="text-red-500 !mb-0 text-[1.2vw] mobile:text-[2.4vw] text-center">{emailMsg}</p>
              )}
            </div>
            <div className="mx-auto w-full px-[1vw] space-y-2">
              <input
                className={`input_name w-full h-[5.156vw] text-[1.5625vw] mobile:text-[3.84vw] ${
                  mobileDisabled
                    ? "!bg-gray-400 cursor-not-allowed !border-gray-200"
                    : ""
                }`}
                defaultValue={undefined}
                type="tel"
                aria-label="phone"
                name="user_mobile"
                value={mobile}
                placeholder="Phone Number"
                autoComplete="off"
                disabled={mobileDisabled}
                onChange={(e) => setMobile(e.target.value)}
                onInput={(e) => validateMobile(e.target.value)}
                onBlur={() => validate()}
                required
              />
              {(typeof mobileMsg === "string") && (
                <p className="text-red-500 !mb-0 text-[1.2vw] mobile:text-[2.4vw] text-center">{mobileMsg}</p>
              )}
            </div>
            <p className="text-center w-full text-[1.04vw] mobile:text-[2.56vw] pt-[1vw]">
              By entering this promotion, you agree to the competition{" "}
              <a
                className="tnc-next"
                aria-label="tnc"
                onClick={handleShowTerms}
              >
                Terms and Conditions
              </a>
              . You also agree to providing your personal information and that
              we may use the personal information we collect from you as set out
              in the Terms and Conditions.
            </p>
            <div className="flex justify-center gap-[4vw] items-center pt-[1vw]">
              <Button
                className="w-[13vw] mobile:w-[38.4vw]"
                onClick={onBack}
              >
                BACK
              </Button>
              <Button
                className="w-[13vw] mobile:w-[38.4vw]"
                disabled={disabled || isReachedLimit}
                onClick={handleSignup}
              >
                NEXT
              </Button>
            </div>
          </form>
        </div>
        {/* <!-- end sign up form --> */}
        {/* <!-- recommend --> */}
        <div className="inner recommend right">
          <img
            src="assets/image/nasonex.svg"
            alt="nasonex"
            className="inner__logo mobile:w-[25.64vw]"
          />
          <h2 className="text-[2.083vw] mobile:text-[6.41vw] w-[70%] bold text-center">
            Do you recommend Nasonex Allergy?
          </h2>
          <div className="button-container gap-[2vw] mobile:gap-[6vw]">
            <Button className="w-[13vw] mobile:w-[38.461vw]" onClick={() => handleRecommend("yes")}>
              YES
            </Button>
            <Button className="w-[13vw]  mobile:w-[38.461vw]" onClick={() => handleRecommend("no")}>
              No
            </Button>
          </div>
        </div>
        {/* <!-- end recommend --> */}
        {/* <!-- why  --> */}
        <div className="inner why right">
          <img
            src="/assets/image/nasonex.svg"
            alt="nasonex"
            className="inner__logo mobile:w-[25.64vw]"
          />
          <h2 className="text-[2.083vw] mobile:text-[6.41vw] mobile:w-[87%] w-[70%] bold text-center">
            Why do you recommend Nasonex Allergy?
          </h2>
          <form className="why-form w-full" action="/how-to-play" autoComplete="off">
            <textarea
              className="w-full mobile:text-[3.846vw] mobile:h-[10vw] mobile:overflow-hidden"
              aria-label="textarea"
              placeholder="Please briefly explain"
              autoComplete="off"
              onChange={(e) => setRecommendReason(e.target.value)}
              required
            ></textarea>

            {typeof recommendReasonMsg === "string" && (
              <p className="text-red-500 text-[1.2vw] mobile:text-[2.4vw] mb-[60px] text-center">{recommendReasonMsg}</p>
            )}

            <Button
              className="w-[16.145vw] mx-auto mobile:w-[38.461vw]"
              disabled={!recommendReason}
              onClick={() => { 
                if (!recommendReason) { 
                  setRecommendReasonMsg('Reason is required')
                  return 
                }
                handleReason(true)
              }}
            >
              SUBMIT
            </Button>
          </form>
        </div>
        {/* <!-- end why  --> */}

        {/* <!-- why not --> */}
        <div className="inner why-not right">
          <img
            src="/assets/image/nasonex.svg"
            alt="nasonex"
            className="inner__logo mobile:w-[25.64vw]"
          />
          <h2 className="text-[2.083vw] mobile:text-[6.41vw] mobile:w-[87%] w-[70%] bold text-center">
            Why don’t you recommend Nasonex Allergy?
          </h2>
          <form className="why-form-not w-full" autoComplete="off">
            <textarea
              className="w-full mobile:text-[3.846vw] mobile:overflow-hidden"
              autoComplete="off"
              aria-label="textarea"
              placeholder="Please briefly explain"
              onChange={(e) => setNoRecommendReason(e.target.value)}
              required
            ></textarea>
            {typeof noRecommendReasonMsg === "string" && (
              <p className="text-red-500 text-[1.2vw] mb-[60px] text-center">{noRecommendReasonMsg}</p>
            )}
            <Button
              className="w-[16.145vw] mx-auto mobile:w-[38.4vw]"
              disabled={!noRecommendReason}
              onClick={() => { 
                if (!noRecommendReason) { 
                  setNoRecommendReasonMsg('Reason is required')
                  return 
                }
                handleReason(false)
              }}
            >
              SUBMIT
            </Button>
          </form>
        </div>
        {/* <!-- end why not --> */}

        {/* <!-- competitor --> */}
        <div className="inner competitor right">
          <img
            src="/assets/image/nasonex.svg"
            alt="nasonex"
            className="inner__logo mobile:w-[25.64vw]"
          />
          <h2 className="text-[2.083vw] mobile:text-[6.41vw] mobile:w-[87%] bold text-center">
            If you don’t recommend Nasonex Allergy, which Allergy brand do you
            recommend and why?
          </h2>
          <form className="why-form-not w-full" action="/how-to-play" autoComplete="off">
            <textarea
              className="w-full mobile:text-[3.846vw] mobile:overflow-hidden"
              autoComplete="off"
              aria-label="textarea"
              placeholder="Please briefly explain"
              onChange={(e) => setOtherRecommendReason(e.target.value)}
              required
            ></textarea>
            {typeof otherRecommendReasonMsg === "string" && (
              <p className="text-red-500 text-[1.2vw] mb-[60px] w-[40.833vw] mx-auto text-center">{otherRecommendReasonMsg}</p>
            )}
            <Button
              disabled={!otherRecommendReason}
              className="w-[16.145vw] mx-auto mobile:w-[38.4vw]"
              onClick={handleOtherReason}
            >
              SUBMIT
            </Button>
          </form>
        </div>
        {/* <!-- end competitor --> */}
      </div>
      <TermsModal
        visible={showTermsModal}
        onClose={() => { 
          playButton();
          setShowTermsModal(false);
        }}
      />
    </>
  );
}

export default Signup;
