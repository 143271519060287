import React, { forwardRef, useEffect, useRef, useMemo } from "react";
import gsap from "gsap";
import useAudio from "../hooks/useAudio";

const isMobile = window.innerWidth <= 992

const Circle = forwardRef(
  (
    {
      id,
      color, // Background color of the circle
      label, // Text to display in the circle
      rotation,
      wrapperRef,
      scale,
      imgSrc, // Image source, overwrites label and color
      wrongSrc,
      clickable,
      onClick, // Function to call when the circle is clicked
      style, // Any additional styles
      isAilment, // Whether the circle is an ailment
      isClicked, // Whether the circle has been clicked
      isFlashing, // Whether the circle is flashing
      isMatch, // Whether match success
      lastClicked,
      currentClicked,
      pointsPerMatch,
    },
    ref
  ) => {
    const { playCircleTap } = useAudio();
    const circleRef = useRef(null);
    const incorrectRef = useRef(null);
    const pointsRef = useRef(null);
    const ailmentRef = useRef(null);
    const matching = !!(lastClicked && currentClicked && currentClicked.id === id && typeof isMatch === "boolean")
    const productImgWidth = isMobile ? '22vw' : '10vw'
    const wrongProductImg = useMemo(() => { 
      return (
        <img
          src={wrongSrc}
          alt={label}
          style={{
            width: productImgWidth,
            height: productImgWidth,
            maxWidth: productImgWidth,
            opacity: 1,
            transform: `rotate(${isAilment ? 0 : rotation}deg)`,
            position: 'relative',
            zIndex: 10
          }}
        />
      )
    }, [isAilment, rotation, wrongSrc, label, productImgWidth])
    const handleClick = (e) => {
      if (!isClicked && clickable) {
        playCircleTap();
        const circle = document.getElementById(`circleWrapper${id}`);
        gsap.fromTo(
          circle,
          {
            scale: scale * 0.75,
            ease: "power4.out",
          },
          {
            scale,
            ease: "power4.out",
          }
        );
        onClick(e);
      }
    };

    const circleClasses = [
      "Circle",
      isClicked && "Clicked",
      isFlashing && "Flashing",
      !isAilment && "Product",
      typeof isMatch === "boolean" && currentClicked?.id === id
        ? isMatch
          ? "Correct"
          : "Wrong"
        : undefined,
    ]
      .filter(Boolean)
      .join(" ");

    const renderContent = () => {
      const productImg = (
        <img
          ref={circleRef}
          src={imgSrc}
          alt={label}
          style={{
            width: productImgWidth,
            height: productImgWidth,
            maxWidth: productImgWidth,
            opacity: 1,
            transform: `rotate(${isAilment ? 0 : rotation}deg)`,
            position: 'relative',
            zIndex: 10
          }}
        />
      );

      if (matching) {
        if (isMatch) {
          return (
            <>
              <div
                ref={pointsRef}
                className="lucky-guy absolute left-0 top-0 opacity-0 transform translate-y-[20px] text-[2vw] mobile:text-[4vw] text-[#91db07] z-50"
                style={{
                  textShadow: `-1px -1px 0 #fff,1px -1px 0 #fff,-1px  1px 0 #fff,1px  1px 0 #fff`,
                }}
              >
                + {pointsPerMatch}
              </div>
              { productImg}
            </>
          );
        } else {
          return (
            <>
              <img
                ref={incorrectRef}
                src="/assets/image/game assets/incorrect.png"
                alt="incorrect"
                className={`absolute -left-[0.5vw] -top-[0.5vw] w-[9vw] h-[9vw] !max-w-[9vw] mobile:w-[21vw] mobile:h-[21vw] mobile:!max-w-[21vw] mobile:!max-h-[21vw] z-30 opacity-100`}
              />
              {
                isAilment ? (<>
                  <img
                    src="/assets/image/live-red.svg"
                    alt="incorrect"
                    className={`absolute -left-[0.5vw] -top-[0.5vw] w-[9vw] h-[9vw] !max-w-[9vw] mobile:-left-[1vw] mobile:-top-[1vw] mobile:w-[22vw] mobile:h-[22vw] mobile:!max-w-[22vw] z-20`}
                  />
                  {productImg}
                </>) : wrongProductImg
              }
            </>
          );
        }
      }

      return (
        <>
          {isAilment && (
            <img
              ref={ailmentRef}
              src="/assets/circle1.svg"
              className={`absolute -left-[0.5vw] -top-[0.5vw] w-[9vw] h-[9vw] !max-w-[9vw] !max-h-[9vw] mobile:w-[21vw] mobile:h-[21vw] mobile:!max-w-[21vw] mobile:!max-h-[21vw]`}
              alt="circle"
            />
          )}
          {productImg}
        </>
      );
    };

    useEffect(() => {
      if (typeof isMatch === "boolean") {
        if (isMatch) {
          if (currentClicked.id === id) { 
            gsap.to(circleRef.current, {
              opacity: 0,
              duration: 0,
              onComplete: () => {
                gsap.to(pointsRef.current, {
                  opacity: 1,
                  duration: 0.3,
                  y: 0,
                });
              },
            });
          }
        } else {
          if (incorrectRef.current) {
            gsap.to(incorrectRef.current, {
              opacity: 0,
              duration: 1.3,
            });
          }
        }
      }
    }, [isMatch, id, scale, currentClicked]);

    useEffect(() => {
      if (typeof isMatch !== "boolean") {
        if (ailmentRef.current && isAilment) {
          gsap.to(ailmentRef.current, {
            opacity: 1,
          });
        }
      }
    }, [isMatch, isAilment]);

    useEffect(() => { 
      if (typeof isMatch !== "boolean") { 
        if (circleRef.current) {
          gsap.to(circleRef.current, {
            opacity: 1,
          });
        }
      }
    }, [isMatch])

    useEffect(() => {
      if (isAilment) {
        gsap.fromTo(
          wrapperRef,
          {
            scale: 0,
          },
          {
            scale,
            duration: 0.5,
          }
        );
      } else {
        gsap.to(wrapperRef, {
          scale,
          duration: scale === 0 ? 0 : 0.5
        });
      }
    }, [scale, isAilment, wrapperRef]);

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          id={`circleWrapper${id}`}
          ref={ref}
          onClick={handleClick}
          className={`${circleClasses} mobile:!w-[20vw] mobile:!h-[20vw] ${isAilment ? 'z-[100]' : ''}`}
          style={{
            backgroundColor:
              (isMatch && currentClicked?.id === id)
                ? "transparent"
                : color,
            ...style,
          }}
        >
          {renderContent()}
        </div>
      </div>
    );
  }
);

export default Circle;
