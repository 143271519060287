import React, { useState, useEffect, useCallback, useRef } from "react";
import { useShallow } from "zustand/react/shallow";
import { useConfigStore } from "../store";
import useAudio from "../hooks/useAudio";
import useDetectDVH from "../hooks/useDetectDVH";
import "../styles/Gameplay.css";

import ScoreBoard from "../components/ScoreBoard";
import GameBoard from "../components/GameBoard";
import Overlay from "../components/Overlay";
import GameOver from "../components/GameOver";

import { ailmentCircles, productCircles } from "../data/CircleData";
import { shuffleArray } from "../utility/Chunky";
import { updateSession } from "../api";

// Score consts
const INITIAL_SCORE = 0; // Starting user score

// Ailment consts
const INITAL_AILMENT_BATCH = 1; // Starting ailments to show at the same time
const AILMENT_INCREASE_FACTOR = 0; // Ailments to increase by as you level up

// Lives consts
const INITIAL_LIVES = 3; // Starting user number of lives
const TOTAL_LIVES = 3; // Total number of lives

// Timer consts
const TIMER_DECREASE_INTERVAL = 3; // Interval for decreasing timer duration, every 3 matches
const TIMER_DECREASE_AMOUNT = 0.5; // Amount to decrease timer duration, in seconds
const TIMER_MIN_DURATION = 1; // Minimum duration per ailment, in seconds
const OVERLAY_DURATION = 500; // In milliseconds

// General consts
const TOTAL_BOARDS = 4; // Number of GameBoards (panels)

const isMobile = window.innerWidth <= 992

function Gameplay({playerId, sessionId, onGameover}) {
  const {
    INITIAL_SCORE_PER_MATCH,
    SCORE_INCREASE_PER_DIFFICULTY,
    INITIAL_TIMER_DURATION,
    TIMES_PER_DAY,
  } = useConfigStore(
    useShallow((state) => ({
      INITIAL_SCORE_PER_MATCH: state.INITIAL_SCORE_PER_MATCH,
      SCORE_INCREASE_PER_DIFFICULTY: state.SCORE_INCREASE_PER_DIFFICULTY,
      INITIAL_TIMER_DURATION: state.INITIAL_TIMER_DURATION,
      TIMES_PER_DAY: state.TIMES_PER_DAY,
    }))
  );
  const prevAilmentIds = useRef([]);
  const [ailmentBatch, setAilmentBatch] = useState(INITAL_AILMENT_BATCH);
  const { playCorrectMatch, playIncorrectMatch } = useAudio();

  const getCircles = useCallback(() => {
    const availableAilments = ailmentCircles.sort(() => Math.random() - 0.5);

    // Select a limited number of ailments
    const selectedAilments = availableAilments
      .filter((circle) => !prevAilmentIds.current.includes(circle.id))
      .slice(0, ailmentBatch)
      .map((circle) => ({
        ...circle,
        isAilment: true,
        rotation: Math.ceil(Math.random() * 360),
        scale: 1,
      }));
        
    prevAilmentIds.current = selectedAilments.map(i => i.id)
  
    console.log('prevAilmentIds.current: ', prevAilmentIds.current)

    let newCircles = [];
    newCircles.push(
      ...selectedAilments.map((circle) => ({
        ...circle,
        isAilment: true,
        isClicked: false,
        rotation: Math.ceil(Math.random() * 360),
        // scale: scaleBase + 0.5 * Math.random(),
        scale: 1,
      }))
    );

    const idsToExclude = selectedAilments.filter((i) => i.matchId && i.matchId2).map(i => i.matchId)

    const prodCircles = productCircles.map((circle) => ({
      ...circle,
      isAilment: false,
      isClicked: false,
      rotation: Math.ceil(Math.random() * 360),
      // scale: idsToExclude.includes(circle.id) ? 0 : scaleBase + 0.5 * Math.random(),
      scale: idsToExclude.includes(circle.id) ? 0 : 1,
    }))

    newCircles.push(...prodCircles);

    return newCircles.sort(() => Math.random() - 0.5);
  }, [ailmentBatch]);

  const [circles, setCircles] = useState([]);
  const [circleChunks, setCircleChunks] = useState([]);
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(INITIAL_SCORE);
  const [pointsPerMatch, setPointsPerMatch] = useState();
  const [lives, setLives] = useState(INITIAL_LIVES);
  const [matches, setMatches] = useState(0);
  const [clickedCircles, setClickedCircles] = useState([]);
  const [lastClicked, setLastClicked] = useState(null);
  const [currentClicked, setCurrentClicked] = useState(null);
  const [timerDuration, setTimerDuration] = useState();
  const timerDurationRef = useRef()
  const [adjustedTimerDuration, setAdjustedTimerDuration] = useState();
  const [selectedAilment, setSelectedAilment] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState(null);
  const [isGameOver, setIsGameOver] = useState(false);
  const [isMatch, setIsMatch] = useState();
  const intervalId = useRef(null);
  const isInitLoad = useRef(true);
  const [clickable, setClickable] = useState(true)

  useDetectDVH();

  const shuffleAndChunkCircles = useCallback(() => {
    const circles = getCircles()
    const shuffledCircles = shuffleArray([...circles]);
    const boardCount = isMobile ? 1 : TOTAL_BOARDS
    const boards = Array.from({ length: boardCount }, () => []);

    shuffledCircles.forEach((circle, index) => {
      boards[index % boardCount].push(circle);
    });

    setCircleChunks(boards);
  }, [getCircles]);

  const triggerOverlay = (message, color) => {
    setOverlayMessage({
      msg: message,
      color: color,
    });

    setShowOverlay(true);
    setTimeout(() => setShowOverlay(false), OVERLAY_DURATION);
  };

  const gameOver = () => {
    setIsGameOver(true);
  };

  const onCircleClick = (circle) => {
    if (circle.isAilment) { 
      setSelectedAilment(circle)
    }

    setClickedCircles((prevClickedCircles) =>
      prevClickedCircles.includes(circle.id)
        ? prevClickedCircles.filter((id) => id !== circle.id)
        : [...prevClickedCircles, circle.id]
    );

    setCurrentClicked(circle);

    if (lastClicked) {
      setClickable(false)
      let isCorrectMatch = false;

      if (lastClicked.isAilment) {
        // When they click the ailment first
        isCorrectMatch =
          lastClicked.matchId === circle.id ||
          lastClicked.matchId2 === circle.id;
      } else {
        // When they click the product first
        isCorrectMatch =
          circle.matchId === lastClicked.id ||
          circle.matchId2 === lastClicked.id;
      }
      isCorrectMatch ? playCorrectMatch() : playIncorrectMatch();
      setIsMatch(isCorrectMatch);
      if (isCorrectMatch) {
        let isUpdateAdjustedTimer = false
        // If matched up correctly
        triggerOverlay("Correct!", "green");

        setLevel(level + 1);

        var matchCount = matches + 1;
        setMatches(matchCount);

        console.log(matchCount, matchCount % TIMER_DECREASE_INTERVAL === 0);

        if (matchCount > 0 && matchCount % TIMER_DECREASE_INTERVAL === 0) {
          // Increase points per match every TIMER_DECREASE_INTERVAL matches
          const newPointsPerMatch =
            pointsPerMatch + SCORE_INCREASE_PER_DIFFICULTY;
          setPointsPerMatch(newPointsPerMatch);

          // Decrease timer duration every TIMER_DECREASE_INTERVAL matches
          const newTimerDuration = Math.max(
            TIMER_MIN_DURATION,
            adjustedTimerDuration - TIMER_DECREASE_AMOUNT
          );
          console.log('newTimerDuration: ', newTimerDuration)
          setAdjustedTimerDuration(newTimerDuration);
          console.log(
            "newAdjustedTimerDuration",
            adjustedTimerDuration,
            "-",
            TIMER_DECREASE_AMOUNT,
            "=",
            newTimerDuration
          );
          isUpdateAdjustedTimer = true
        }
        setTimeout(() => {
          continueGame(isUpdateAdjustedTimer);
        }, 1000);
      } else {
        // If not matched up correctly
        triggerOverlay("WRONG", "red");
        setLives(lives - 1);

        setTimeout(() => {
          setClickable(true)
          setLastClicked(null);
          setCurrentClicked(null);
          setClickedCircles([]);
          setIsMatch();
          setSelectedAilment(null)

          if (lives <= 1) {
            gameOver();
          }
        }, 1000);
      }
    } else {
      setLastClicked(circle);
    }
  };

  const continueGame = (isUpdateAdjustedTimer) => {
    setClickable(true)
    setScore(score + pointsPerMatch);
    setClickedCircles([]);
    setAilmentBatch(ailmentBatch + AILMENT_INCREASE_FACTOR);
    setLastClicked(null);
    shuffleAndChunkCircles();
    if (!isUpdateAdjustedTimer) { 
      setTimerDuration(adjustedTimerDuration);
      timerDurationRef.current = adjustedTimerDuration
    }
    setIsMatch();
    setCurrentClicked(null);
    setSelectedAilment(null)

    console.log("new timerDuration", adjustedTimerDuration);
  };

  // Update timer duration when adjustedTimerDuration changes
  useEffect(() => {
    if (typeof adjustedTimerDuration === "number") {
      clearInterval(intervalId.current)
      setTimerDuration(adjustedTimerDuration);
      timerDurationRef.current = adjustedTimerDuration
      intervalId.current = setInterval(() => {
        if (timerDurationRef.current <= 0) { 
          gameOver();
          clearInterval(intervalId.current)
          return
        }
        timerDurationRef.current = timerDurationRef.current - 0.1
        // setTimerDuration((prevTimerDuration) => prevTimerDuration - 0.1); // Decrease by 1000 milliseconds (1 second)
        setTimerDuration(timerDurationRef.current)
      }, 100)
    }
  }, [adjustedTimerDuration]);

  useEffect(() => {
    if (typeof INITIAL_TIMER_DURATION === "number") {
      setAdjustedTimerDuration(INITIAL_TIMER_DURATION);
    }
  }, [INITIAL_TIMER_DURATION]);

  useEffect(() => {
    if (typeof INITIAL_SCORE_PER_MATCH === "number") {
      setPointsPerMatch(INITIAL_SCORE_PER_MATCH);
    }
  }, [INITIAL_SCORE_PER_MATCH]);

  useEffect(() => {
    const update = async () => {
      await updateSession(sessionId, {
        totalScore: score,
      });
    };
    if (isGameOver && sessionId) {
      update();
    }
  }, [isGameOver, sessionId, score]);

  useEffect(() => { 
    if (isGameOver) { 
      clearInterval(intervalId.current)
    }
  }, [isGameOver])

  useEffect(() => { 
    const circles = getCircles()
    const shuffledCircles = shuffleArray([...circles]);
    const boardCount = isMobile ? 1 : TOTAL_BOARDS
    const boards = Array.from({ length: boardCount }, () => []);

    shuffledCircles.forEach((circle, index) => {
      boards[index % boardCount].push(circle);
    });

    setCircleChunks(boards);

    isInitLoad.current = false
  }, [])

  return (
    <>
      <img
        src="/assets/image/background.png"
        className="background-image"
        alt="background"
      />
      <div className="page-container play mobile:h-[100dvh]">
        <ScoreBoard score={score} lives={lives} totalLives={TOTAL_LIVES} timerDuration={timerDuration} />
        <div className="play-canvas mobile:!grid-cols-1">
          {circleChunks.map((chunk, index) => (
            <GameBoard
              key={index}
              circles={chunk}
              level={level}
              clickable={clickable}
              onCircleClick={onCircleClick}
              isMatch={isMatch}
              flashingCircleId={selectedAilment?.id}
              clickedCircles={clickedCircles}
              lastClicked={lastClicked}
              currentClicked={currentClicked}
              pointsPerMatch={pointsPerMatch}
            />
          ))}
          <Overlay
            message={overlayMessage?.msg}
            color={overlayMessage?.color}
            isVisible={showOverlay}
            onClose={() => setShowOverlay(false)}
          />
          {isGameOver && (
            <GameOver score={score} playerId={playerId} sessionId={sessionId} onGameover={onGameover} />
          )}
        </div>
      </div>
    </>
  );
}

export default Gameplay;
