import axios from "axios";

const token =
  "5e840856556c2970c4caf092d6e9700228674f26441f91a124e54f0d75f459f13fa076498b9406e5e38f159f426b2aeb50feeeb86edd19f545ae1cb282eb9e236ed41200fa264f351429736ef26b45e020e5b4ed6d84dcfe9439020b5e5ba29df79812fff5a55c30266065eb93bfb4ea19a42b443b019ca84a4cb540c5bf3900";

const service = axios.create({
  baseURL: "https://bea-social-matchem-admin.2dm.digital/api",
  timeout: 30 * 1000, //300000
});

service.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log({ error });
    Promise.reject(error);
  }
);

export default service;
