import React, { useEffect, useState } from "react";
import "./styles/Global.css";
import { useConfigStore } from "./store";
import useAudio from "./hooks/useAudio";
import Splash from "./templates/Splash";
import Gameplay from "./templates/Gameplay";
import Leaderboard from "./templates/Leaderboard";
import Signup from "./templates/Signup";
import HowToPlay from "./templates/HowToPlay";
import Background from './components/Background'

function App() {
  const { loadConfig } = useConfigStore();
  const [step, setStep] = useState('menu');
  const [playerId, setPlayerId] = useState();
  const [sessionId, setSessionId] = useState();
  const { playGame, globalSound, gameSound } = useAudio();

  const handleStartPlay = () => { 
    setStep('signup')
  }

  const handleCountdown = () => {
    globalSound.fade(0.5, 0, 1000);
    globalSound.pause();
    setStep('game')
  }

  const handlePlay = (sessionId) => { 
    setSessionId(sessionId)
  }

  const handleSignup = (playerId, isJump) => { 
    setPlayerId(playerId)
    if (isJump) { 
      setStep('how-to-play')
    }
  }

  const handleGameover = () => { 
    setStep('leaderboard')
    if (!globalSound.playing()) { 
      globalSound.play();
      globalSound.fade(0, 0.5, 1000);
    }
  }

  const handlePlayAgain = (sessionId, playerId) => { 
    globalSound.fade(0.5, 0, 1000);
    globalSound.pause()
    setPlayerId(playerId);
    setSessionId(sessionId);
    setStep('game')
  }

  const handleReturn = () => { 
    setStep('menu')
  }

  useEffect(() => {
    loadConfig();
    document.addEventListener('contextmenu', function(event) {
      event.preventDefault();
    }, false);
  }, []);

  useEffect(() => { 
    if (step === 'game') {
      globalSound.fade(0.75, 0, 1000)
      setTimeout(() => { 
        globalSound.pause();
        playGame();
      }, 1000)
    } else { 
      gameSound.pause()
      if (!globalSound.playing()) { 
        globalSound.play();
        globalSound.fade(0, 0.5, 1000);
      }
    }
  }, [step, playGame, globalSound, gameSound])

  return (<>
    {step === 'menu' && <Splash onStart={handleStartPlay} />}
    {step === 'how-to-play' && <HowToPlay sessionId={sessionId} playerId={playerId} onPlay={handlePlay} onCountdown={handleCountdown} />}
    { step === 'signup' &&  <Signup onSignup={handleSignup} onBack={() => setStep('menu')} />}
    {step === 'game' && <Gameplay playerId={playerId} sessionId={sessionId} onGameover={handleGameover}  />}
    {step === 'leaderboard' && <Leaderboard sessionId={sessionId} playerId={playerId} onPlayAgain={handlePlayAgain} onReturn={handleReturn} />}
    <div
      className="fixed left-0 top-0  w-full h-screen z-[1]"
    >
      <Background />
    </div>
    <div
      className="fixed left-0 top-0  w-full h-screen"
      style={{
        backgroundImage: `radial-gradient(circle at center, #1000C4 0%, black 40%)`
      }}
    >
    </div>
  </>);
}

export default App;

