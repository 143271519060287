import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import Loading from "./Loading";

function Terms({ visible, onClose }) {
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const closeRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    gsap.to(contentRef.current, {
      scale: visible ? 1 : 0,
      duration: 0.2,
      delay: 0.1,
      ease: "linear",
    });
  }, [visible]);

  return (
    <div
      ref={containerRef}
      className={`fixed left-0 top-0 w-full h-screen z-10 ${
        visible ? "block" : "hidden"
      }`}
    >
      <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-90"></div>
      <a
        ref={closeRef}
        className="absolute right-[9%] mobile:right-[4%] top-[10%] mobile:top-[7%] active:scale-75 transform transition"
        onClick={onClose}
      >
        <img src="/assets/close.png" className="w-[91px] mobile:w-[10vw]" />
      </a>
      <div
        ref={contentRef}
        className={`absolute left-1/2 top-[15%] h-[85%] w-1/2 mobile:w-[90%] transform -translate-x-1/2 overflow-y-scroll pr-4 mobile:pr-0 scale-0`}
      >
        {!isLoaded && (
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <Loading />
          </div>
        )}

        <iframe
          title="Terms"
          src="https://www.plexus.co/terms/app-2024-win-an-ipad-promotion"
          className={`w-full border-none ${isLoaded ? "h-[2000px] mobile:h-[2500px]" : "h-0"}`}
          onLoad={() => setIsLoaded(true)}
        />
      </div>
    </div>
  );
}

export default Terms;
