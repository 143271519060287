import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { Document, Page, pdfjs } from "react-pdf";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;

function Product({ visible, product, onClose }) {
  const [numPages, setNumPages] = useState(0);
  const contentRef = useRef(null);
  const closeRef = useRef(null);
  const [pageHeight, setPageHeight] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onPageLoadSuccess = ({ height }) => {
    setPageHeight(height);
  };

  useEffect(() => {
    if (visible) {
      gsap.to(contentRef.current, {
        scale: 1,
        duration: 0.3,
      });
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <div className={`fixed left-0 top-0 w-full h-screen z-10`}>
      <div className="absolute left-0 top-0 w-full h-full bg-black bg-opacity-90"></div>
      <a
        ref={closeRef}
        className="absolute right-[9%] mobile:right-[4%] top-[10%] mobile:top-[7%] active:scale-75 transform transition"
        onClick={onClose}
      >
        <img src="/assets/close.png" className="w-[91px] mobile:w-[10vw]" />
      </a>
      <div
        ref={contentRef}
        className="absolute left-1/2 top-[15%] h-[85%] w-1/2 mobile:w-[90%] transform -translate-x-1/2 overflow-y-scroll pr-4 mobile:pr-0 scale-0 overflow-x-hidden"
      >
        <div
          className="w-full bg-white"
          style={{
            height: `${pageHeight * numPages}px`,
          }}
        >
          <Document
            file={product.pdf}
            className="w-full"
            loading={"Loading..."}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (_, index) => (
              <Page
                key={index}
                pageNumber={index + 1}
                width={window.innerWidth <= 992 ? window.innerWidth * 0.9 : window.innerWidth / 2 - 26}
                onLoadSuccess={index === 0 ? onPageLoadSuccess : null}
              />
            ))}
          </Document>
        </div>
      </div>
    </div>
  );
}

export default Product;
