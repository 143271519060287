import { Howl } from 'howler'
const buttonSound = new Howl({
  src: ["/audios/menu-button-pressed.aac"],
});

const countdownSound = new Howl({
  src: ["/audios/3-2-1-play.aac"],
})

const circleTapSound = new Howl({
  src: ["/audios/game-tap-bubble.aac"],
})

const gameoverSound = new Howl({
  src: ["/audios/matchem-game-over.aac"],
})

const correctMatchSound = new Howl({
  src: ["/audios/correct-match.aac"]
})

const incorrectMatchSound = new Howl({
  src: ["/audios/incorrect-match.aac"]
})

const globalSound = new Howl({
  src: ["/audios/menu.aac"],
  loop: true,
  autoplay: true,
  volume: 0.5,
})

const gameSound = new Howl({
  src: ["/audios/game.aac"],
  loop: true
})

export default function useAudio() { 
  const playButton = () => { 
    buttonSound.play();
  }

  const playCountdown = () => { 
    countdownSound.play();
  }

  const playCircleTap = () => { 
    circleTapSound.play();
  }

  const playGameover = () => { 
    gameoverSound.play();
  }

  const playCorrectMatch = () => { 
    correctMatchSound.play();
  }

  const playIncorrectMatch = () => { 
    incorrectMatchSound.play();
  }

  const playGlobal = () => { 
    globalSound.play();
  }

  const playGame = () => { 
    gameSound.play();
  }

  return {
    playButton,
    playCountdown,
    playCircleTap,
    playGameover,
    playCorrectMatch,
    playIncorrectMatch,
    playGlobal,
    playGame,
    globalSound,
    gameSound
  }
}