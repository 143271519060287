export function shuffleArray(array) {
    let currentIndex = array.length, randomIndex;
  
    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }
  
  export function chunkArray(array, totalChunks) {
    const chunks = [];
    const chunkSize = Math.ceil(array.length / totalChunks);
  
    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      chunks.push(array.slice(start, start + chunkSize));
    }
  
    return chunks;
  }