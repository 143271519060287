import React, { useEffect, useState } from "react";
import useAudio from "../hooks/useAudio";
import ProductModal from "../components/Product";
import Button from "../components/Button";
import useDetectDVH from '../hooks/useDetectDVH';

const Products = [
  {
    id: 1,
    img: "/assets/image/elevit.svg",
    pdf: "/assets/pdfs/elevit.pdf",
  },
  {
    id: 2,
    img: "/assets/image/menevit.svg",
    pdf: "/assets/pdfs/menevit.pdf",
  },
  {
    id: 3,
    img: "/assets/image/nasonex.svg",
    pdf: "/assets/pdfs/nasonex.pdf",
  },
  {
    id: 4,
    img: "/assets/image/claratyne.svg",
    pdf: "/assets/pdfs/nasonex.pdf",
  },
  {
    id: 5,
    img: "/assets/image/iberogast1.svg",
    pdf: "/assets/pdfs/iberogast.pdf",
  },
  {
    id: 6,
    img: "/assets/image/berocca.svg",
    pdf: "/assets/pdfs/berocca.pdf",
  },
];

function Splash({ onStart }) {
  const { playButton } = useAudio();
  const [showProduct, setShowProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  useDetectDVH();

  const handleButtonClick = () => {
    playButton()
    onStart()
  };

  const handleClickProduct = (product) => {
    playButton()
    setSelectedProduct(product);
    setShowProduct(true);
  };

  const handleClose = () => {
    playButton();
    setSelectedProduct(null);
    setShowProduct(false);
  };

  useEffect(() => {
    const imageGrid = document.querySelectorAll(".image-grid img");
    var i = 0;
    imageGrid.forEach(function (e) {
      setTimeout(() => {
        e.classList.add("show");
      }, i * 200);
      i++;
    });
  }, []);

  return (
    <>
      <div className="page-container splash mobile:h-[100dvh] mobile:overflow-auto">
        <header className="single-image">
          <a href="#" aria-label="bayer">
            <img
              src="/assets/image/bayer logo.svg"
              alt="bayer logo"
              className="w-[5.83vw] max-w-[5.83vw] mobile:w-[11vw] mobile:max-w-[11vw]"
            />
          </a>
        </header>
        <div className="content mobile:relative desktop:absolute left-1/2 mobile:w-full w-[40%] desktop:top-[40%] mobile:top-[15%] transform -translate-x-1/2 -translate-y-1/2 z-10">
          <div className="content__top">
            <img
              className="matchem-logo mobile:w-[83vw] !mobile:max-w-[83vw] w-[40vw] max-w-[40vw]"
              src="/assets/Match'Em up Logo.svg"
              alt="matchem up logo"
            />
            <Button
              className="w-[13vw] play-desktop"
              onClick={handleButtonClick}
            >
              PLAY
            </Button>
          </div>
        </div>
        <div className="content__bottom desktop:absolute mobile:mt-[6vw] left-0 pl-[4vw] pr-[4vw] desktop:bottom-[2vw] w-full">
          <div className="col col-1">
            <p className="text-[1.5625vw] mobile:text-[3.84vw] mobile:!mt-[6vw]">Tap a brand to learn more</p>
            <div className="image-grid">
              {Products.map((p) => (
                <a
                  key={p.id}
                  aria-label="elevit"
                  style={p.style || {}}
                  onClick={() => handleClickProduct(p)}
                >
                  <img
                    src={p.img}
                    alt="elevit"
                    className="max-w-[8vw] mobile:mx-auto mobile:w-[19.22vw] mobile:max-w-[19.22vw]"
                    style={p.imgStyle || {}}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="col col-2 flex items-end">
            <img
              src="/assets/image/win-ipad.png"
              className="w-[14.115vw] max-w-[14.115vw] mobile:w-[31.28vw] mobile:max-w-[31.28vw]"
              alt="win ipad"
            />
          </div>
        </div>
        <div className="w-full mobile:flex hidden mobile:relative desktop:absolute mobile:mt-[10vw] bottom-[3%] left-1/2 transform -translate-x-1/2 justify-center">
          <Button
            className="w-[38.46vw] play-mobile"
            onClick={handleButtonClick}
          >
            PLAY
          </Button>
        </div>

      </div>
      <ProductModal
        visible={showProduct}
        product={selectedProduct}
        onClose={handleClose}
      />
    </>
  );
}

export default Splash;
