function Button({ children, className, disabled, ...restProps }) {
  return (
    <div
      className={`relative group lg:text-[3.4vw] text-[7.692vw]  ${className}`}
      {...restProps}
    >
      <div
        className={`relative ${
          disabled ? "bg-[#939b9f]" : "bg-[#0091DF]"
        }  rounded-[20px] mobile:rounded-[10px] mobile:py-[9px] text-white font-luckiestGuy  flex items-center z-10 group-active:translate-y-[9%] leading-[1.4] w-full justify-center`}
      >
        <div className="transform translate-y-[10%]">{children}</div>
      </div>
      <div
        className="absolute left-0 bottom-0 w-full h-full transform translate-y-[9%] z-0 rounded-[22px] mobile:rounded-[12px]"
        style={{
          backgroundImage: !disabled
            ? `linear-gradient(135deg, rgba(144, 204, 237, .4) 0%, rgba(144, 204, 237, 1) 59%, rgba(144, 204, 237, .4) 100%)`
            : `linear-gradient(135deg, rgba(147, 155, 159, .4) 0%, rgba(147, 155, 159, 1) 59%, rgba(147, 155, 159, .4) 100%)`,
        }}
      ></div>
    </div>
  );
}

export default Button;
