import { useCallback, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y } from "swiper/modules";
import { createSession } from "../api";
import Progress from "../components/Progress";
import Button from "../components/Button";
import useAudio from "../hooks/useAudio";
import Header from '../components/Header';

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function HowToPlay({playerId, sessionId, onPlay, onCountdown}) {
  const [showProgress, setShowProgress] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const { playButton } = useAudio();

  const handleStart = useCallback(async () => {
    playButton();
    const res = await createSession({
      player: {
        connect: [playerId],
      },
    });
    onPlay(res.data.id)
    setShowContent(false);
    setShowProgress(true);
  }, [playerId, playButton, onPlay]);

  return (
    <>
      <img
        src="assets/image/background.png"
        className="background-image"
        alt="background"
      />
      <div className="page-container how-to-play h-screen">
        <Header />
        {showContent && (
          <div className="container-play absolute w-full top-[25%] left-0 px-[5.21vw] mobile:!mt-0">
            <div className="row-howtoplay">
              <div className="set-column">
                <h1 className="lucky-guy text-[3.385vw] mobile:text-[7.692vw] mobile:mb-[6vw]">how to play</h1>
                <div className="space-y-5 mobile:space-y-[4vw]">
                  <p className="text-[1.21vw] mobile:text-[3.846vw]">
                  The aim of the game is to correctly match as many Bayer products that are the remedy for the displayed symptoms before your lives reach 0. You will begin with 3 lives, and lose a life for every incorrect match.                  </p>
                  <p className="text-[1.21vw] mobile:text-[3.846vw]">
                  Symptoms will appear in a blue ring while products are in green rings. Tapping the correct Bayer product will increase  your score. As you continue to play, your time to match products with symptoms will decrease, and the points you earn will increase – so be quick!                  </p>
                </div>
              </div>
              <div className="set-column mobile:hidden">
                <video className="w-full" autoPlay loop>
                  <source src="/videos/matchem-onboarding.mp4" type="video/mp4" />
                </video>
                {/* <Swiper
                  slidesPerView={1}
                  spaceBetween={5}
                  modules={[Pagination, Scrollbar, A11y]}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 5,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 5,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 5,
                    },
                  }}
                >
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-1.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-2.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-3.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-4.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-2.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide">
                    <img
                      src="assets/image/how-to-3.png"
                      alt="how-to-play"
                      className="mx-auto block w-[11.09vw] max-w-[11.09vw]"
                    />
                  </SwiperSlide>
                </Swiper> */}

                <div className="description-mobile">
                  <p className="font-20">
                    By entering this promotion, you agree to the competition{" "}
                    <a href="/" aria-label="terms conditionas">
                      Terms and Conditions.
                    </a>{" "}
                    You also agree to providing your personal information and
                    that we may use the personal information we collect from you
                    as set out in the Terms and Conditions.
                  </p>
                </div>
              </div>
            </div>
            <Button className="w-[13vw] mx-auto mt-[4vw] mobile:w-[38.4vw] mobile:fixed mobile:bottom-[5%] mobile:left-1/2 mobile:transform mobile:-translate-x-1/2" onClick={handleStart}>
              START
            </Button>
          </div>
        )}
        <Progress
          visible={showProgress}
          sessionId={sessionId}
          playerId={playerId}
          onCountdown={onCountdown}
        />{" "}
      </div>
    </>
  );
}

export default HowToPlay;
