function Header() { 
  return (
    <header>
      <a href="/" aria-label="bayer">
        <img
          src="/assets/Match'Em up Logo_D3.svg"
          alt="bayer logo"
          className="w-[15.625vw] max-w-[15.625vw] mobile:w-[25.897vw] mobile:max-w-[25.897vw]"
        />
      </a>
      <a href="#" aria-label="bayer">
        <img
          src="assets/image/bayer logo.svg"
          alt="bayer logo"
          className="w-[5.833vw] max-w-[5.833vw] mobile:w-[11.125vw] mobile:max-w-[11.125vw]"
        />
      </a>
    </header>
  )
}

export default Header