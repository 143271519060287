import React from 'react';

const Overlay = ({ isVisible, message, color }) => {
    if (!isVisible) return null;

    const overlayMessageStyle = color ? { color: color } : {};

    return (
        <div className="Overlay">
            <div className="OverlayMessage font-luckiestGuy" style={overlayMessageStyle}>
                {message}
            </div>
        </div>
    );
};

export default Overlay;