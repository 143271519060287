import { useEffect } from "react";
import useAudio from "../hooks/useAudio";
import Button from "./Button";

const GameOver = ({ score, playerId, sessionId, onGameover }) => {
  const { playGameover, playButton } = useAudio();
  const OVERLAY_DURATION = 3000; // In milliseconds

  useEffect(() => {
    const timer = setTimeout(() => {
      onGameover()
    }, OVERLAY_DURATION);

    return () => clearTimeout(timer); // Cleanup the timer
  }, [score, onGameover, playerId, sessionId]);

  useEffect(() => { 
    playGameover();
  }, [])

  return (
    <>
      <div className="fixed left-0 top-0 w-full h-screen bg-black bg-opacity-60 transition duration-[600]"></div>
      <div className="game-over-popup fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full flex items-center justify-center transition flex-col">
        <h2 className="text-white ">GAME OVER</h2>
        <Button
          className="w-[13vw] mx-auto mobile:w-[38.4vw]"
          onClick={() => {
            playButton();
            onGameover();
          }}
        >
          NEXT
        </Button>
      </div>
    </>
  );
};

export default GameOver;
