import { useEffect } from "react";

export default function useDetectDVH( containerClass = '.page-container') {
  useEffect(() => {
    const containerBg = document.querySelector(containerClass)

    if ('CSS' in window && 'supports' in window.CSS) {
      if (!window.CSS.supports('height', '100dvh')) {
        containerBg.style.height = window.innerHeight + 'px'
      }
    }
  }, [])
}