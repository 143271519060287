import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const DonutSketch = () => {
  const sketchRef = useRef();

  useEffect(() => { 
    const myp5 = new p5(function (p) { 
      console.log('p: ', p.background)
      let numTorus = 5;
      let motionSpeed = 0.1;
      let torusArray = [];
      let counter = 0;


      function getNextPos(i) { 
        let safeDistance = 45; // Minimum distance from any other donut
        let attempts = 0;
        let maxAttempts = 100; // To prevent an infinite loop
        let posData;
      
        do {
          posData = p.createVector();
          switch(i % 4) { // Use modulo to ensure it works even if i > 3
            case 0:
              // top left
              posData.set(p.random(-p.width / 2, 0), p.random(-p.height / 2, 0));
              break;
            case 1:
              // top right
              posData.set(p.random(0, p.width / 2), p.random(-p.height / 2, 0));
              break;
            case 2:
              // bottom right
              posData.set(p.random(0, p.width / 2), p.random(0, p.height / 2));
              break;
            case 3:
              // bottom left
              posData.set(p.random(-p.width / 2, 0), p.random(0, p.height / 2));
              break;
          }
      
          // Check if posData is at least safeDistance away from all other donuts
          let isTooClose = torusArray.some(tor => {
            let d = p.dist(posData.x, posData.y, tor.pos.x, tor.pos.y);
            return d < safeDistance;
          });
      
          if (!isTooClose) break; // Found a safe position
          attempts++;
        } while (attempts < maxAttempts);
      
        if (attempts === maxAttempts) {
          console.warn("Could not find a safe position for a new Donut");
        } else {
          console.log("Spawned a new donut")
        }
      
        return posData;
      }

      class Donut {
        constructor(indx, sz) {
          this.pos = getNextPos(indx);
          this.xRot = p.random(p.TWO_PI);
          this.yRot = p.random(p.TWO_PI);
          this.size = sz ? sz : p.random(0.1, 0.3);
          this.col = p.random(['#AFCA0A', '#00BAEE'])
          this.destroy = false;
        }
        
        update() {
          if(this.size < 0.01) {
            this.size = p.lerp(this.size, 0.1, 0.2);
          } else if(this.size <= 0.5) {
            this.size += 0.0001;
          }
          this.xRot += 0.0003;
          this.yRot += 0.0004;
          
          // add motion away from centre
          if(this.pos.x < 0) {
            this.pos.x -= motionSpeed;
          } else {
            this.pos.x += motionSpeed;
          }
          
          if(this.pos.y < 0) {
            this.pos.y -= motionSpeed;
          } else {
            this.pos.y += motionSpeed;
          }
          
          this.draw();
        }
        
        draw() {
          p.push()
            p.translate(this.pos.x, this.pos.y)
            p.scale(this.size);
            p.rotateX(this.xRot)
            p.rotateY(this.yRot)
            p.fill(this.col);
            p.torus(p.width/4, p.width/40, 40, 16);
          p.pop()
        }
      }

      p.setup = () => {
        const canvas = p.createCanvas(window.innerWidth, window.innerHeight, p.WEBGL);
        p.noStroke();

        canvas.style('filter', 'blur(10px)');

        for(let i = 0; i < numTorus; i++) {
          torusArray.push(new Donut(counter))
          counter++;
        }
      };

      p.draw = () => { 
        p.background('rgba(0, 0, 0, 0)')

        for(let tor of torusArray) {
          tor.update();
          if(tor.pos.x > p.width*0.6 || tor.pos.x < -p.width*0.6 || tor.pos.y < -p.height*0.6 || tor.pos.y > p.height*0.6) {
            // is off the screen
            tor.destroy = true;
          }
        }

        torusArray = torusArray.filter(tor => !tor.destroy);

        if(p.frameCount % 300 === 0 && torusArray.length < 12) {
          torusArray.push(new Donut(counter, 0.001))
          counter++;
        }
      }

    }, sketchRef.current);

    return () => { 
      myp5.remove()
    }
  }, [])

  return <div ref={sketchRef}></div>;
};

export default DonutSketch;
