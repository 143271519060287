import React from "react";

const Lives = Array.from({ length: 3 }, () => ({}));

const ScoreBoard = ({ score, lives, timerDuration, totalLives }) => {
  const seconds = timerDuration < 0 ? 0 : Math.floor(timerDuration)

  return (
    <header className="header-play z-[3]">
      <a href="/" aria-label="bayer">
        <img
          src="/assets/Match'Em up Logo_D3.svg"
          alt="bayer logo"
          className="w-[15.625vw] max-w-[15.625vw] mobile:w-[25.897vw] mobile:max-w-[25.897vw]"
        />
      </a>
      <div className="score mr-[2vw] gap-[1vw] mobile:!flex-col mobile:!items-end ">
        <div className="flex items-center gap-2 mobile:gap-0 mobile:!justify-end">
          <p className="lucky-guy text-[2.08vw] px-0 mobile:text-[4vw]">LIVES:</p>
          <div className="lives-container transform -translate-y-[10%] mobile:-translate-y-[25%] mobile:!gap-[2vw]">
            {Lives.map((_, index) => (
              <div key={index} className="lives">
                <img
                  src={index + 1 <= lives ? "/assets/image/live-green.png" : "/assets/image/live-red.png"}
                  alt="live green"
                  className={`w-[2.55vw] max-w-[2.55vw] mobile:w-[4vw] mobile:max-w-[4vw] ${index + 1 > lives ? 'mt-[2px]' : ''}`}
                />
              </div>
            ))}
          </div>
        </div>

        <p className="lucky-guy text-[2.08vw] mobile:text-[4vw] px-0 flex items-center gap-[0.5vw]">
          SCORE:{" "}
          <span className="lucky-guy text-[3.08vw] mobile:text-[4.5vw] text-green score-count transform">
            {score}
          </span>
        </p>
        <p className="lucky-guy text-[2.08vw] mobile:text-[4vw] px-0 flex items-center gap-[0.5vw] mobile:!ml-0">
          TIME:{" "}
          <span className={`lucky-guy text-[3.08vw] mobile:text-[4.5vw] score-count transform ${Math.floor(timerDuration) > 3 ? 'text-green' : 'text-red'}`}>
            {seconds || '0'}
          </span>
        </p>
      </div>
      <a href="#" aria-label="bayer" alt="bayer" className="mobile:hidden">
        <img
          src="assets/image/bayer logo.svg"
          alt="bayer logo"
          className="w-[5.833vw] max-w-[5.833vw] mobile:w-[11.125vw] mobile:max-w-[11.125vw]"
        />
      </a>
    </header>
  );
};

export default ScoreBoard;
