export const productCircles = [
  {
    id: 1,
    label: "Claratyne",
    color: "white",
    imgSrc: "/assets/logos/correct_claratyne_ring.svg",
    wrongSrc: "/assets/logos/wrong_claratyne_ring.svg",
  },
  {
    id: 2,
    label: "Nasonex",
    color: "white",
    imgSrc: "/assets/logos/correct_nasonex_ring.svg",
    wrongSrc: "/assets/logos/wrong_nasonex_ring.svg",
  },
  {
    id: 3,
    label: "Berocca",
    color: "white",
    imgSrc: "/assets/logos/correct_berocca_ring.svg",
    wrongSrc: "/assets/logos/wrong_berocca_ring.svg",
  },
  {
    id: 4,
    label: "Iberogast",
    color: "white",
    imgSrc: "/assets/logos/correct_iberogast_ring.svg",
    wrongSrc: "/assets/logos/wrong_iberogast_ring.svg",
  },
  {
    id: 5,
    label: "Elevit",
    color: "white",
    imgSrc: "/assets/logos/correct_elevit_ring.svg",
    wrongSrc: "/assets/logos/wrong_elevit_ring.svg",
  },
  {
    id: 6,
    label: "Menevit",
    color: "white",
    imgSrc: "/assets/logos/correct_menevit_ring.svg",
    wrongSrc: "/assets/logos/wrong_menevit_ring.svg",
  },
];

export const ailmentCircles = [
  {
    id: 101,
    matchId: 1,
    matchId2: 2,
    label: "Sneezing",
    color: "white",
    imgSrc: "/assets/aliments/Bea _sneezing.svg",
  },
  {
    id: 102,
    matchId: 1,
    matchId2: 2,
    label: "Itchy eyes",
    color: "white",
    imgSrc: "/assets/aliments/Bea__itchy eyes .svg",
  },
  {
    id: 103,
    matchId: 1,
    matchId2: 0,
    label: "Itchy Rash",
    color: "white",
    imgSrc: "/assets/aliments/Bea _itchy rash.svg",
  },
  {
    id: 104,
    matchId: 1,
    matchId2: 2,
    label: "24hr Allergy Relief",
    color: "white",
    imgSrc: "/assets/aliments/Bea _24hr allergy relief.svg",
  },
  {
    id: 105,
    matchId: 1,
    matchId2: 2,
    label: "Runny nose",
    color: "white",
    imgSrc: "/assets/aliments/Bea _runny nose.svg",
  },
  {
    id: 106,
    matchId: 2,
    matchId2: 0,
    label: "Nasal Spray",
    color: "white",
    imgSrc: "/assets/aliments/Bea _nasal spary.svg",
  },
  {
    id: 107,
    matchId: 1,
    matchId2: 0,
    label: "Fast Allergy Relief",
    color: "white",
    imgSrc: "/assets/aliments/Bea _fast allergy rekief.svg",
  },
  {
    id: 108,
    matchId: 2,
    matchId2: 0,
    label: "Nasal Congestion",
    color: "white",
    imgSrc: "/assets/aliments/Bea _nasal congestion.svg",
  },
  {
    id: 109,
    matchId: 1,
    matchId2: 2,
    label: "Itchy nose",
    color: "white",
    imgSrc: "/assets/aliments/Bea _itchy nose.svg",
  },

  {
    id: 110,
    matchId: 3,
    matchId2: 0,
    label: "Enhanced Energy levels",
    color: "white",
    imgSrc: "/assets/aliments/Bea _enhanced energy levels.svg",
  },
  {
    id: 111,
    matchId: 3,
    matchId2: 0,
    label: "Low energy",
    color: "white",
    imgSrc: "/assets/aliments/Bea _low energy.svg",
  },
  {
    id: 112,
    matchId: 4,
    matchId2: 0,
    label: "Stomach pain",
    color: "white",
    imgSrc: "/assets/aliments/Bea _stomach oain.svg",
  },
  {
    id: 113,
    matchId: 4,
    matchId2: 0,
    label: "Indigestion",
    color: "white",
    imgSrc: "/assets/aliments/Bea _indifgestion.svg",
  },
  {
    id: 114,
    matchId: 4,
    matchId2: 0,
    label: "IBS",
    color: "white",
    imgSrc: "/assets/aliments/Bea _ibs.svg",
  },
  {
    id: 115,
    matchId: 4,
    matchId2: 0,
    label: "Digestive symptoms",
    color: "white",
    imgSrc: "/assets/aliments/Bea _distive symptoms.svg",
  },
  {
    id: 116,
    matchId: 5,
    matchId2: 0,
    label: "Baby's healthy development",
    color: "white",
    imgSrc: "/assets/aliments/Bea _baby_s healthy.svg",
  },

  {
    id: 117,
    matchId: 5,
    matchId2: 0,
    label: "Pregnancy multivitamin",
    color: "white",
    imgSrc: "/assets/aliments/Bea _pregnancy.svg",
  },
  {
    id: 118,
    matchId: 5,
    matchId2: 0,
    label: "Female preconception health",
    color: "white",
    imgSrc: "/assets/aliments/Bea _female.svg",
  },
  {
    id: 119,
    matchId: 6,
    matchId2: 0,
    label: "Male reproductive health",
    color: "white",
    imgSrc: "/assets/aliments/Bea _male.svg",
  },
  {
    id: 120,
    matchId: 6,
    matchId2: 0,
    label: "Sperm Health",
    color: "white",
    imgSrc: "/assets/aliments/Bea _sperm health.svg",
  },
];
