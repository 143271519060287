import React, { useCallback, useEffect, useState, useRef } from "react";
import gsap from 'gsap';
import { createSession, getLeaderboardData, getSession, getPlayer, getSessions } from "../api";
import Button from "../components/Button";
import useAudio from "../hooks/useAudio";
import Header from '../components/Header';
import { useConfigStore } from "../store";

function Leaderboard({ playerId, sessionId, onPlayAgain, onReturn }) {
  const { playButton } = useAudio();
  const { TIMES_PER_DAY } = useConfigStore((state) => ({
    TIMES_PER_DAY: state.TIMES_PER_DAY,
  }));
  const [dataSource, setDataSource] = useState([]);
  const [currentSession, setCurrentSession] = useState();
  const [isReachedLimit, setIsReachedLimit] = useState(false);
  const [limitMsg, setLimitMsg] = useState()
  const scoreRef = useRef(null);

  const checkPlayLimit = useCallback(async () => { 
    const playerRes = await getPlayer(playerId)
    const { email } = playerRes.data.attributes
    const sessionRes = await getSessions({
      "filters[player][email][$eq]": email,
      "filters[createdAt][$gte]": new Date(
        new Date().setHours(0, 0, 0, 0)
      ).toISOString(),
      "filters[createdAt][$lte]": new Date(
        new Date().setHours(23, 59, 59, 0)
      ).toISOString(),
    });
    const todayPlayCount = sessionRes.data.length;
    if (todayPlayCount >= TIMES_PER_DAY) {
      setIsReachedLimit(true);
      setLimitMsg(
        "You have reached the maximum number of plays for today"
      );
    }
  }, [playerId, TIMES_PER_DAY])

  const playAgain = useCallback(async () => {
    if (isReachedLimit) return
    const res = await createSession({
      player: {
        connect: [playerId],
      },
    });
    onPlayAgain(res.data.id, playerId )
  }, [playerId, isReachedLimit, onPlayAgain]);

  const loadData = async () => {
    const res = await getLeaderboardData({
      asc_desc: "ASC",
      size: 20,
    });
    setDataSource(res);
  };

  const loadPlayerData = async () => {
    const res = await getSession(sessionId);
    setCurrentSession({
      id: res.data.id,
      ...res.data.attributes,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (sessionId) {
      loadPlayerData();
    }
  }, [sessionId]);

  useEffect(() => { 
    if (playerId) { 
      checkPlayLimit()
    }
  }, [playerId, TIMES_PER_DAY, checkPlayLimit])

  useEffect(() => { 
    if (typeof currentSession?.totalScore === 'number') { 
      gsap.to(scoreRef.current, {
        textContent: currentSession?.totalScore,
        duration: 1.5,
        ease: 'Power1.easeIn',
        snap: { textContent: 1 },
        stagger: 1,
      })
    }
  }, [currentSession?.totalScore])

  return (
    <>
      <img
        src="/assets/image/background.png"
        className="background-image"
        alt="background"
      />
      <div className="page-container play-again mobile:h-[100dvh] mobile:!overflow-auto">
        <Header />
        <div className="leaderboard-inner mt-[4vw]">
          <div className="col col-1 justify-between">
            <h1 className="text-[3.385vw] lucky-guy mobile:text-[7.692vw]">LEADERBOARD</h1>
            <div>
              <h2 className="text-[2.34vw] lucky-guy mobile:text-[5.128vw]">Your Score</h2>
              <h3 ref={scoreRef} className="text-[7.8125vw] lucky-guy mobile:text-center mobile:text-[13.333vw]">
                &nbsp;
              </h3>
            </div>
            <div className="flex flex-col gap-[1.2vw] mobile:gap-[4vw]">
              <Button
                className="mobile:w-[50vw] text-[7.692vw] w-[21vw] order-3"
                onClick={() => { 
                  playButton();
                  onReturn();
                }}
              >
                HOME
              </Button>
              <Button
                className="w-[50vw] text-[7.692vw] lg:w-[21vw] order-3"
                disabled={isReachedLimit}
                onClick={() => { 
                  playButton();
                  playAgain();
                }}
              >
                PLAY AGAIN
              </Button>
            </div>
            {!!limitMsg && (
              <p className="text-red-500 !mb-0 mobile:mt-[4vw] text-[1.2vw] mobile:text-[2.4vw]">{limitMsg}</p>
            )}
          </div>
          <div className="col col-2">
            <div className="leaderboard-table text-[1.5625vw]">
              <div className="table-head bold mobile:text-[3.146vw]">
                <div>RANK</div>
                <div>NAME</div>
                <div>SCORE</div>
              </div>
              <div className="table-content">
                <div className="table-content-holder">
                  {dataSource.map((item, index) => (
                    <div key={item.id} className="table-item mobile:text-[3.846vw]">
                      <div>{index + 1}</div>
                      <div>{item.player.name}</div>
                      <div>{item.playerMaxScore}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="description text-[0.78125vw] mobile:text-[2.564vw]">
              <p>
                Berocca
                <br /> Always read the label and follow the directions for use.
                Berocca has been clinically tested in 7 studies and proven to
                support energy levels. Sarris et al. 2021, Nutr Hosp.
                9;38(6):1277-1286.
              </p>
              <p>
                Nasonex
                <br />
                1. Sastre J and Mosges R. J Investig Allergol Clin Immunol 2012;
                22:1-12. 2. Graft D, et al. J Allergy Clin Immunol 1996;
                98:724–31. Study funded by Schering-Plough Research Institute.
                3. Penagos M, et al. Allergy 2008; 63:1280–91. 4. ASCIA,
                Allergic Rhinitis Clinical Update, 2022. Available at:{" "}
                <a
                  href="https://allergy.org.au/hp/papers/allergicrhinitis-clinical-update/"
                  aria-label="allergy"
                >
                  https://allergy.org.au/hp/papers/allergicrhinitis-clinical-update/
                </a>
                (accessed January 2023).
              </p>
              <p>
                Claratyne
                <br />
                Compared to oral antihistamines for allergic rhinitis
              </p>
              <p>
                Elevit / Menevit
                <br />
                Always read the label and follow the directions for use.
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Leaderboard;
