export function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function today() {
  const date = new Date();

  return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}T`;
}
